import React from 'react'
import ReactDOM from 'react-dom/client'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import { ToastContainer } from 'react-toastify'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'

import { frFR as corefrFR, enUS as coreenUS } from '@mui/material/locale'
import { frFR, enUS } from '@mui/x-date-pickers/locales'
import { frFR as dataGridfrFR, enUS as dataGridenUS } from '@mui/x-data-grid'
import { disableDevTools } from 'disable-react-devtools'
import * as Helper from './common/Helper'
import * as UserService from './services/UserService'
import { strings as commonStrings } from './lang/common'
import Env from './config/env.config'
import App from './App'

import 'react-toastify/dist/ReactToastify.min.css'
import './assets/css/common.css'
import './assets/css/index.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

if (process.env.REACT_APP_NODE_ENV === 'production') {
  disableDevTools()
}

let language = Env.DEFAULT_LANGUAGE
const user = JSON.parse(localStorage.getItem('bc-user') ?? 'null')
let lang = UserService.getQueryLanguage()

if (lang) {
  if (!Env.LANGUAGES.includes(lang)) {
    lang = localStorage.getItem('bc-language')

    if (lang && !Env.LANGUAGES.includes(lang)) {
      lang = Env.DEFAULT_LANGUAGE
    }
  }

  try {
    if (user) {
      language = user.language
      if (lang && lang.length === 2 && user.language !== lang) {
        const data = {
          id: user.id,
          language: lang,
        }

        const status = await UserService.validateAccessToken()

        if (status === 200) {
          const _status = await UserService.updateLanguage(data)
          if (_status !== 200) {
            Helper.error(null, commonStrings.CHANGE_LANGUAGE_ERROR)
          }
        }

        language = lang
      }
    } else if (lang) {
      language = lang
    }
    UserService.setLanguage(language)
    commonStrings.setLanguage(language)
  } catch (err) {
    Helper.error(err, commonStrings.CHANGE_LANGUAGE_ERROR)
  }
}

language = UserService.getLanguage()
const isFr = language === 'fr'

const theme = createTheme(
  {
    typography: {
      fontFamily: [
        '"Source Sans 3"'
      ].join(','),
    },
    palette: {
      primary: { main: '#ECAD0C' },
      text: {
        primary: 'rgba(255, 255, 255, 0.89)',
        secondary: 'rgba(255, 255, 255, 0.6)'
      }
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            backgroundColor: '#151515',
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            backgroundColor: '#1b1b1b'
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            color: '#ECAD0C',
            textDecorationColor: '#ECAD0C'
          }
        }
      },
      MuiBottomNavigation: {
        styleOverrides: {
          root: {
            backgroundColor: '#232323'
          }
        }
      },
      MuiBottomNavigationAction: {
        styleOverrides: {
          root: {
            color: 'rgba(255, 255, 255, 0.6)',
            '&.Mui-selected': {
              color: 'rgba(255, 255, 255, 0.89)'
            }
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: 100,
            backgroundColor: '#1b1b1b',
            borderColor: 'rgba(255, 255, 255, 0.2)',
            color: 'rgba(255, 255, 255, 0.2)',
            // [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            //   borderColor: 'rgba(255, 255, 255, 0.4)',
            //   color: 'rgba(255, 255, 255, 0.4)',
            // },
            [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
              borderColor: 'rgba(255, 255, 255, 0.4)',
              color: 'rgba(255, 255, 255, 0.4)',
            },
          },
          notchedOutline: {
            borderColor: 'rgba(255, 255, 255, 0.2)',
            color: 'rgba(255, 255, 255, 0.2)',
            '&.Mui-focused': {
              borderColor: 'rgba(255, 255, 255, 0.6)',
            },
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: 'rgba(255, 255, 255, 0.4)',
            '&.Mui-focused': {
              borderColor: 'rgba(255, 255, 255, 0.4)',
              color: 'rgba(255, 255, 255, 0.4)',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 100
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            color: '#1b1b1b'
          }
        }
      }
    },
  },
  isFr ? frFR : enUS,
  isFr ? dataGridfrFR : dataGridenUS,
  isFr ? corefrFR : coreenUS,
)

root.render(
  <ThemeProvider theme={theme}>
    <CssBaseline>
      <App />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        icon
        theme="dark"
      />
    </CssBaseline>
  </ThemeProvider>,
)
